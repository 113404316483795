export default {
  page: {
    title: {
      base: 'Poarta de plată Autopay',
      start: 'Alegerea metodei de plată',
      visadata: 'Visa Mobile',
      continue: 'Continuare - Alegerea unei metode de plată',
      wait: 'Așteptare',
      confirmation: 'Confirmarea platii',
      thankYou: 'Mulțumesc pentru plată',
      error: 'Eroare de pagină',
      errorNotFound: 'Pagina nu a fost gasita',
      secureBack: 'Se încarcă pagina',
      maintenance: 'Site web în întreținere',
      regulationsApproval: 'Regulamente și politica de confidențialitate',
      toTransferData: 'detalii transfer',
      sessionTimeout: 'Pagina după încheierea sesiunii',
      paywayError: 'Canalul de plată indisponibil'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Măriți dimensiunea fontului',
      decreaseFontSize: 'Reduceți dimensiunea fontului',
      changeContrast: 'Schimbați contrastul',
      changeLanguage: 'Schimbați limba site-ului',
      skipToContent: 'Salt la conținut',
      skipToContentLabel: 'Treci la conținutul principal',
      logoTitle: 'Sigla site-ului'
    },
    sessionTimer: {
      timeOut: 'Sesiunea a expirat',
      sessionEnd: 'Sfârșitul sesiunii pentru:',
      label: 'Extindeți durata sesiunii',
      button: 'Extindeți'
    }
  },
  footer: {
    text1: 'Aveți întrebări suplimentare despre plățile online sau despre alte servicii ale noastre? <a title="Se deschide într-o fereastră nouă" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Accesați pagina de ajutor</a>',
    text2: {
      part1: 'Administratorul datelor cu caracter personal este Autopay S.A.',
      part2: 'str. Powstańców Warszawy 6, 81-718 Sopot. Furnizarea datelor cu caracter personal este voluntară, dar este necesară pentru îndeplinirea cererii depuse. Temeiul juridic, scopul, durata de prelucrare a datelor cu caracter personal și drepturile Utilizatorului, precum și alte informații importante privind principiile de prelucrare a datelor cu caracter personal sunt detaliate în <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Accesați pagina cu documentul Politicii de confidențialitate (128 KB, PDF)" rel="noreferrer">Politica de confidențialitate a Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Vizualizați informații suplimentare',
    linkShowMoreText: 'Citeste mai mult',
    linkShowLessTitle: 'Ascundeți informații suplimentare',
    linkShowLessText: 'Citeste mai puțin'
  },
  paywall: {
    header: 'Selectați metoda de plată',
    info: {
      paywayNotAvailable: 'Ne pare foarte rău, dar se pare că metoda de plată selectată nu este posibilă pe dispozitivul dvs. în acest moment',
      applePayNotAvailable: 'Ne pare foarte rău, dar se pare că plata cu Apple Pay nu este posibilă pe dispozitivul dvs. în acest moment',
      googlePayNotAvailable: 'Ne pare foarte rău, dar se pare că plata cu Google Pay nu este posibilă pe dispozitivul dvs. în acest moment'
    },
    paywayGroup: {
      inactiveMessage: 'Momentan, plata este indisponibilă',
      notice: {
        novelty: 'NOUTATE',
        promotion: 'PROMOȚIE',
        recommended: 'RECOMANDAT',
        mastercard: 'RAPID ȘI ÎN SIGURANȚĂ',
        blik: 'BLIKOMANIA - Înregistrează-te și câștigă diamante!',
        visaMobile: 'Plătește fluid și în siguranță'
      },
      bank: {
        many: 'bănci',
        one: 'bancă',
        two: 'bănci'
      },
      wallet: {
        many: 'portofele',
        one: 'portofel',
        two: 'portofele'
      },
      otp: {
        many: 'moduri',
        one: 'cale',
        two: 'moduri'
      },
      blikPayLater: {
        info: 'Află mai multe'
      }
    },
    paywayList: {
      label: 'Grup de tip de plată',
      linkSelectOther: 'Alege altul'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Am un cont la o altă bancă'
    },
    paywaySwitch: {
      methodShortSingle: 'de unică folosință',
      methodLongSingle: 'Plăți unice',
      methodShortAuto: 'Ciclic',
      methodLongAuto: 'Plăți recurente'
    },
    form: {
      payway: {
        label: 'Selectare plată'
      },
      card: {
        label: 'Introduceți detaliile cardului',
        iframe: {
          title: 'Formularul cu detaliile cardului de credit trebuie completat'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Formular cu numărul de telefon care urmează să fie completat'
        },
        status: {
          pending: {
            statusText: 'În așteptarea confirmării',
            header: 'Confirmaţi plata în aplicaţie',
            descriptionText1: 'Veți confirma plata în aplicația Visa Mobile sau într-o aplicație bancară cu Visa Mobile activată',
            descriptionText2: 'După confirmarea tranzacției în aplicație, așteptați rezultatul plății',
            descriptionText3: 'Anularea tranzacției în curs',
            cancelButton: 'Anulați plata'
          },
          success: {
            statusText: 'Vă mulțumesc că ați făcut achiziția!',
            descriptionText: 'Plata finalizată cu succes'
          },
          error: {
            statusText: 'Asigurați-vă că aveți fonduri suficiente pe cardul de plată utilizat pentru plata Visa Mobile.',
            descriptionText: 'Plată respinsă'
          }
        },
        apps: 'Aplicații care acceptă plățile Visa Mobile',
        help: 'Aveți nevoie de ajutor?',
        info: 'Aflați mai multe',
        secure: 'Plată securizată cu criptare SSL',
        modal2: {
          header: 'Pentru a confirma plata, deschideți Visa Mobile sau aplicația bancară în care aveți serviciul Visa Mobile active',
          listText1: 'Aveți aplicația Visa Mobile sau serviciul activ Visa serviciul mobil instalat în aplicația mobilă a băncii dumneavoastră',
          listText2: 'aveți aplicația Visa Mobile instalată sau serviciul Visa Mobile activat în aplicația mobilă a băncii dumneavoastră',
          listText3: 'Numărul pe care l-ați dat este corect: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'De asemenea, asigurați-vă că...'
        }
      },
      click2pay: {
        loaderText: 'Szukamy Twojego adresu e-mail<br>w <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Zapisz swoją kartę',
          text2: 'Twoje dane zostaną bezpiecznie zapisane w <strong>Mastercard Click to Pay</strong>',
          link: 'Dowiedz się więcej'
        },
        email: {
          labelForClick2Pay: 'Adres e-mail do powiadomień o płatności'
        },
        cardSelect: {
          selected: 'Wybrana',
          select: 'Wybierz',
          payOther: 'Wpisz dane karty',
          back: 'Powrót'
        },
        cardSelected: {
          header: 'Użyj karty zapisanej w Click to Pay',
          date: 'ważna do',
          buttonChange: 'Zmień'
        },
        codeForm: {
          description: 'Znaleźliśmy Twój adres e-mail w Click to Pay.',
          codeSentEmail: 'Wpisz jednorazowy kod, który wysłaliśmy na adres {email} w celu zalogowania się na konto.',
          codeSentPhone: 'Wpisz jednorazowy kod SMS, który wysłaliśmy na nr {phone} w celu zalogowania się na konto.',
          codeLabel: 'Podaj kod',
          codePlaceholder: 'podaj kod',
          codeLabelFlow: 'Kod',
          codeSendLabel: 'Wyślij nowy kod',
          codeSendAriaLabel: 'przez',
          codeSendAriaLabel2: 'lub',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          buttonSubmit: 'Dalej',
          buttonChangeCard: 'Wprowadź dane karty'
        },
        info: {
          description: 'Płatność kartą jest zaszyfrowana i bezpieczna. Transakcja zostanie autoryzowana za pomocą 3DSecure na stronie banku.'
        },
        loginForm: {
          emailLabel: 'Podaj adres e-mail, aby zalogować się do Click to Pay',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Zaloguj się',
          buttonBack: 'Powrót'
        },
        newUserForm: {
          header: 'Zapisz kartę w Click to Pay',
          description: 'Potrzebujemy jeszcze kilku informacji, by zapisać Twoją kartę.',
          emailLabel: 'Podaj adres e-mail',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Kraj',
          firstnameLabel: 'Imię',
          firstnamePlaceholder: 'imię',
          firstnameLabelFlow: 'Imię',
          lastnameLabel: 'Nazwisko',
          lastnamePlaceholder: 'nazwisko',
          lastnameLabelFlow: 'Nazwisko',
          phoneLabel: 'Numer telefonu',
          phonePlaceholder: 'numer telefonu',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kod',
          countryCodeLabelFlow: 'Kod',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          termsLabel: 'Kontynuując, akceptujesz <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Warunki korzystania ze strony</a> i&nbsp;rozumiesz, że Twoje dane będą przetwarzane zgodnie z <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Polityką prywatności</a> Mastercard.',
          c2pInfo: 'Click to Pay użyje tych informacji, aby Cię zweryfikować i wysyłać na ten numer kody weryfikacyjne. Mogą zostać naliczone opłaty za wiadomości/transmisję danych.',
          buttonSubmit: 'Zapisz i zapłać',
          buttonBack: 'Zapłać bez zapisywania karty'
        },
        profile: {
          header: 'Zaloguj się do Click to Pay',
          buttonChangeEmail: 'Zmień adres e-mail'
        },
        modal: {
          title: 'Click to Pay - informacje',
          header: 'Płać szybko i bezpiecznie, bez przepisywania danych karty w nowym standardzie Click to Pay',
          text1: 'Podczas płatności kartą szukaj logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> i płać kartą płatniczą szybko i wygodnie.',
          text2: 'Click To Pay to nowy standard płatności kartą wspierany przez Visa i Mastercard.',
          text3: 'Kartą na koncie Click to Pay zapłacisz w sklepach w Polsce i za granicą, bez przepisywania danych z karty.',
          text4: 'Dane zapisywane są bezpiecznie w Visa lub Mastercard. Wszystkie informacje są w pełni szyfrowane.',
          text5: 'Podczas kolejnych płatności Click to Pay rozpozna Twój adres e-mail, dzięki czemu od razu zapłacisz zapisanymi kartami.',
          text6: 'Płatności potwierdzasz dodatkowo w swoim banku, co gwarantuje pełne bezpieczeństwo.',
          processError: 'W trakcie wykonywania płatności Click to Pay wystąpił błąd. Wykonaj standardową płatność kartą.'
        }
      },
      email: {
        label: 'Vă rugăm să introduceți o adresă de e-mail',
        flowLabel: 'E-mail',
        tip: 'Avem nevoie de adresa dvs. de e-mail pentru a vă putea trimite mesaje cu privire la situația plății.'
      },
      terms: {
        header: 'Aprobări și declarații',
        selectAll: 'Selectarea tuturor',
        fieldNotRequired: 'Câmp opțional',
        partnerMarketingTextLess: 'Doriți să primiți alerte prin e-mail, SMS sau telefon despre cele mai bune oferte, promoții, concursuri și alte evenimente interesante organizate de noi sau de partenerii noștri?',
        partnerMarketingTextMore: 'Promitem să nu spămuim! Dacă mesajele noastre vă plictisesc, vă puteți retrage consimțământul în orice moment.',
        tradeMarketingTextLess: 'Doriți să transmitem e-mailul sau numărul dvs. de telefon partenerilor noștri de încredere pentru ca aceștia să vă prezinte informații și oferte interesante?',
        tradeMarketingTextMore: 'Vă asigurăm că partenerii noștri sunt companii consacrate care, ca și noi, nu agreează spamming-ul și că vă puteți revoca consimțământul în orice moment'
      },
      termsLabelDefault: 'Am citit și accept Termenii și condițiile <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descărcați fișierul cu termenii și condițiile" rel="noreferrer">ale serviciilor de plată</a> și <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Descărcați fișierul cu politica de confidențialitate Autopay S.A.">Politica de confidențialitate</a>. Doresc ca serviciul să fie livrat imediat, iar în cazul retragerii din contract, știu că nu voi primi o rambursare a costurilor suportate pentru serviciile furnizate, la cererea mea, până în momentul retragerii din contract.',
      termsReccuring: 'Dacă doriți să efectuați o plată, acceptați <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descărcați fișierul cu termenii serviciului" rel="noreferrer">Reglementare</a> și <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Descărcați fișierul cu politica de confidențialitate Autopay S.A.">Politica de confidențialitate</a> Autopay S.A., consimt la executarea imediată a serviciului de plată și consimt la debitarea cardului meu de plată.',
      termsReccuringMore1: 'Sunt de acord ca serviciul să fie prestat fără întârziere și, în caz de retragere, știu că nu voi fi rambursat pentru serviciile prestate la cererea mea până când nu mă retrag din contract.',
      termsReccuringMore2: 'Sunt de acord ca cardul meu de plată să fie debitat periodic de către Autopay S.A. în scopul procesării tranzacțiilor pentru {receiverName}. Regulile privind debitarea ciclică a cardului sunt prevăzute în <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descarcă fișierul cu regulamentul serviciului" rel="noreferrer">Regulamentul</a> pentru prestarea serviciilor de plată. Declar că am luat cunoștință de termenele, frecvența și regulile de stabilire a sumelor care urmează să fie debitate de pe cardul meu de plată, precum și de modul de revocare a acestui consimțământ, care au fost convenite de mine cu {receiverName}. Îl voi contacta pe {receiverName} în cazul în care voi avea întrebări cu privire la debitarea ciclică a cardului de plată.',
      mwfClauseLess: 'Dacă doriți să efectuați o plată, acceptați <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Descărcați fișierul cu termenii serviciului" rel="noreferrer">Reglementare</a> și <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Descărcați fișierul cu politica de confidențialitate Autopay S.A.">Politica de confidențialitate Autopay S.A.</a>, sunteți de acord ca banca dvs. să inițieze o tranzacție prin intermediul Autopay S.A. și să furnizeze detalii despre tranzacția respectivă destinatarului și să solicite un serviciu de plată imediată. ',
      mwfClauseMore: 'Vreau ca serviciul să fie prestat cu promptitudine și știu că nu voi fi rambursat dacă mă retrag din contract. ',
      mwfClauseARIA: 'Dacă doriți să efectuați o plată, acceptați Termenii și Condițiile și Politica de confidențialitate ale Autopay S.A., sunteți de acord să inițiați o tranzacție cu banca dumneavoastră prin intermediul Autopay S.A. și să furnizați detaliile acestei tranzacții beneficiarului plății și să solicitați un serviciu de plată imediată. Doresc ca serviciul să fie furnizat cu promptitudine și știu că nu voi fi rambursat dacă mă retrag din contract. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Descărcați fișierul cu termenii și condițiile de utilizare a serviciilor</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Descărcați fișierul cu politica de confidențialitate a Autopay S.A.</a>',
      paymentInfo: 'Ordinul de plată este transmis prin intermediul Autopay S.A. cu sediul social în  Sopot și  va fi executat în condițiile specificate de banca dumneavoastră. După ce ați  selectat  banca, veți fi autorizat(ă) să plătiți.',
      changePaywayLink1: 'Încă mai puteți',
      changePaywayLink2: 'Modifică forma de plată',
      linkMore: 'MAI MULTE',
      linkShowMoreTitle: 'Vizualizați informații suplimentare',
      linkLess: 'MAI PUȚIN',
      linkShowLessTitle: 'Ascundeți informații suplimentare',
      submitButton: {
        visaMobile: 'Plătiți de',
        fastTransfer: 'Generați detaliile transferului',
        mwf: 'Acceptați și plătiți',
        pis: 'Accept și încep plata',
        auto: 'Comandați plata recurentă',
        autoPayment: 'Plata comanda',
        other: 'Eu plătesc.',
        orange: 'A confirma',
        c2pNext: 'Dalej',
        c2pAccept: 'Akceptuję',
        c2pSaveAndPay: 'Zapisz i zapłać'
      }
    },
    frData: {
      pageTitle: 'Date detaliate pentru transfer',
      header: 'Efectuați un transfer utilizând datele dvs.',
      nrbLabel: 'Contul destinatarului',
      nameLabel: 'Numele destinatarului',
      addressLabel: 'Adresa destinatarului',
      titleLabel: 'Titlul de transfer',
      amountLabel: 'Suma de transfer',
      timeLabel: 'Tranzacție executată de',
      linkCopy: 'Copie',
      linkGetPdf: 'Descărcați PDF cu date',
      linkGetPdfTitle: 'Se deschide într-o fereastră nouă',
      linkGoToBank: 'Conectează-te la bancă',
      info: 'Logaț-vă în contul bancar. Copiați datele din transfer. Este important ca detaliile să fie aceleași, nu schimbați suma, moneda, titlul etc.'
    },
    processing: {
      pageTitle: 'Pagina de așteptare pentru procesarea tranzacției',
      loaderAnimation: 'Animație afișată în timpul așteptării',
      header: 'Pregătim pagina de plată',
      description: 'Vă rugăm să așteptați un pic mai mult...',
      info: {
        header: 'Rețineți!',
        description: 'Odată ce tranzacția este finalizată, nu închideți pagina - dacă o faceți, banii nu vor fi trimiși destinatarului'
      },
      loaderText: 'Transferul de date este în curs<br>de desfășurare'
    }
  },
  thankYou: {
    pageTitle: 'Informații despre rezultatul tranzacției',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Revenire la pagina partenerului'
    },
    negative: {
      header: 'Plata nu a fost finalizată din cauza tranzacției neautorizate.',
      description: 'Dacă totuși doriți să profitați de oferta unui anumit vânzător sau furnizor de servicii - reveniți pe site-ul lor web, alegeți un produs sau un serviciu și plătiți utilizând metoda pe care o alegeți.'
    },
    pending: {
      header: 'Vă mulțumim că ați comandat plata prin intermediul portalului de plată.',
      description: 'Verificarea stării tranzacției este în curs de desfășurare. Când procesul este finalizat, veți primi informații privind starea plății în e-mail.'
    },
    positive: {
      header: 'Vă mulțumim, plata dvs. a fost finalizată.',
      description: 'Vom trimite confirmarea la adresa de e-mail pe care ați furnizat-o.'
    }
  },
  regulationsApproval: {
    header: 'Acceptă tranzacția'
  },
  wait: {
    pageTitle: 'Pagina de așteptare'
  },
  sessionTimeout: {
    pageTitle: 'Pagina după încheierea sesiunii',
    header: 'Timpul pentru efectuarea plății a expirat',
    description: 'Puteți reveni oricând pe site-ul partenerului, să retrimiteți comanda sau să utilizați serviciul și să efectuați plata prin metoda pe care o alegeți.'
  },
  paywayError: {
    pageTitle: 'Canalul de plată selectat nu este disponibil',
    header: 'Din păcate, metoda de plată selectată este momentan indisponibilă.',
    description: 'Vă rugăm să reveniți la pagina partenerului și să încercați din nou mai târziu.'
  },
  loader: {
    defaultAnimation: 'Animație afișată în timp ce pagina se încarcă',
    slider: 'Glisor cu texte în schimbare',
    text1: 'La Autopay, ne propunem să fim neutri din punct de vedere al emisiilor de carbon. Faceți și dumneavoastră acest lucru folosind serviciile noastre',
    text2: 'La Autopay mergem cu bicicleta la serviciu, reducând emisiile de gaze cu efect de seră în atmosferă. Faceți și dumneavoastră acest lucru folosind serviciile noastre',
    text3: 'La Autopay suntem alimentați cu energie verde.<br>Și dumneavoastră, atunci când utilizați serviciile noastre',
    text4: 'La Autopay, ne reducem consumul de hârtie. Faceți acest lucru și cu serviciile noastre'
  },
  orderDetails: {
    header: 'Detalii',
    headerAriaLabel: 'Detalii tranzacție',
    amountLabel: 'Suma datorată',
    receiverLabel: 'Destinatar',
    orderLabel: 'Număr de comandă',
    amountOryginalCurrencyLabel: 'Suma în {currency}',
    currencyRateLabel: 'Rata de schimb valutar',
    feeLabel: 'Taxa de procesare a tranzacției',
    customerNumberLabel: 'Număr de client',
    invoiceNumberLabel: 'Numărul facturii',
    accountNumberLabel: 'Numărul de cont',
    paywayLabel: 'Metoda de plată',
    paywayName: 'Transfer online',
    linkBack: 'Înapoi la magazin',
    linkBackTitle: 'Lăsați tranzacția și întoarceți-vă la magazin',
    linkMore: 'mai multe',
    linkLess: 'mai puțin',
    toggleBtnUnfoldAriaLabel: 'Extindeți detaliile comenzii',
    toggleBtnFoldAriaLabel: 'Închide detaliile comenzii'
  },
  error: {
    pageTitle: 'Pagina de eroare',
    linkBackUrlSet: 'Întoarceți-vă la site-ul partenerului',
    linkBackUrlNotSet: 'Accesați pagina de ajutor',
    sessionTimeout: {
      title: 'Timpul pentru efectuarea plății a expirat',
      message: 'Puteți reveni oricând pe site-ul partenerului, să retrimiteți comanda sau să utilizați serviciul și să efectuați plata prin metoda pe care o alegeți.'
    },
    notFoundError: {
      pageTitle: 'Pagina de eroare 404 - pagina nu a fost găsită',
      title: 'Se pare că ați navigat prea departe și nu ați reușit să găsiți locul pe care doriți să îl vizitați.',
      message: 'Cauze posibile: pagina nu există sau a fost eliminată, sunt în curs lucrări de întreținere, adresa paginii este incorectă, s-a produs o eroare tehnică.'
    },
    generalError: {
      title: 'Ne pare rău',
      message: 'În prezent nu putem procesa transferul dumneavoastră.'
    },
    errFieldNotFound: {
      title: 'Ne pare rău',
      message: ''
    },
    errBadClientSource: {
      title: 'Ne pare rău',
      message: ''
    },
    nrParametersError: {
      title: 'Ne pare rău',
      message: 'În prezent nu putem procesa transferul dumneavoastră.'
    },
    transactionOutdated: {
      title: 'Timpul pentru finalizarea plății a trecut.',
      message: 'Comandă din nou.'
    },
    linkValidityTimeOutdated: {
      title: 'Timpul pentru finalizarea plății a trecut.',
      message: 'Comandă din nou.'
    },
    transactionValidityTimeOutdated: {
      title: 'Timpul pentru finalizarea plății a trecut.',
      message: 'Comandă din nou.'
    },
    multiplyTransaction: {
      title: 'Ne pare rău',
      message: 'Tranzacția există deja și așteaptă plata.'
    },
    transactionCanceled: {
      title: 'Ne pare rău',
      message1: 'Tranzacția a fost anulată.',
      message2: 'Comandă din nou.'
    },
    multiplyPaidTransaction: {
      title: 'Ne pare rău',
      message: 'Tranzacția este deja plătită.'
    },
    transactionReject: {
      title: 'Ne pare rău',
      message1: 'Ups, ceva nu a mers bine.',
      message2: 'Deja reparăm obiectivul...'
    },
    bankDisabled: {
      title: 'Ne pare rău',
      message: 'Banca selectată este momentan indisponibilă.'
    },
    bankTemporaryMaintenance: {
      title: 'Ne pare rău',
      message: 'În prezent nu putem procesa transferul dumneavoastră.'
    },
    insufficientStartAmount: {
      title: 'Ne pare rău',
      message: 'Limita sumei de transfer a fost depășită.'
    },
    startAmountOutOfRange: {
      title: 'Ne pare rău',
      message: 'Limita sumei de transfer a fost depășită.'
    },
    nonAccountedLimitExceeded: {
      title: 'Ne pare rău',
      message: 'Plata nu poate fi finalizată deoarece vânzătorul a atins limita lunară de vânzări.'
    }
  },
  confirmation: {
    pageTitle: 'Informații despre progresul plății',
    linkBack: 'Mă întorc pe site',
    linkBackTimeout: 'Reveniți pe site-ul partenerului în ',
    incorrectAmount: {
      header: 'Suma greșită',
      description: 'Nu am transferat plata dvs. către destinatar deoarece suma era incorectă.',
      description2: 'Faceți clic pe butonul pentru a reveni la pagină și refaceți-o. Nu uitați să nu schimbați nicio dată.'
    },
    multiplePaid: {
      header: 'Depunere dublă',
      description: 'Destinatarul a primit plata dvs. de două ori. Nu vă faceți griji - veți primi rambursarea.',
      description2: 'Contactați destinatarul și furnizați-i confirmarea plății.'
    },
    negative: {
      header: 'Sfârșitul timpului',
      description: 'Nu v-am transferat plata către destinatar deoarece timpul de confirmare a expirat.',
      description2: 'Faceți clic pe butonul pentru a reveni la pagină și refaceți-o.'
    },
    notFound: {
      header: 'Fără plată',
      description: 'A apărut o eroare la procesarea plății. Dar nimic nu se pierde.',
      description2: 'Faceți clic pe butonul pentru a reveni la pagină și refaceți-o.'
    },
    outdated: {
      header: 'Sfârșitul timpului',
      description: 'Nu am transferat plata dvs. către destinatar.',
      description2: 'Faceți clic pe butonul pentru a reveni la pagină și refaceți-o.'
    },
    outdatedPaidNotRefunded: {
      header: 'Sfârșitul timpului',
      description: 'Nu am transferat plata dvs. către destinatar. Dacă banii au fost retrase din contul dvs., nu vă faceți griji, veți primi rambursarea în câteva zile.',
      description2: 'Faceți clic pe butonul pentru a reveni la pagină și refaceți-o.'
    },
    outdatedPaidRefunded: {
      header: 'Sfârșitul timpului',
      description: 'Nu am transferat plata dvs. către destinatar. Dacă banii au fost retrași din contul dvs., nu vă faceți griji, i-am rambursat deja.',
      description2: 'Faceți clic pe butonul pentru a reveni la pagină și refaceți-o.'
    },
    pending: {
      header: 'În curs',
      description: 'Vă procesăm plata. Vă vom anunța când ajunge la destinatar.'
    },
    positive: {
      header: 'Plătit',
      visaVideo: 'Video publicitar redat după o tranzacție reușită',
      description: 'Am transferat fondurile dumneavoastră către destinatar.'
    }
  },
  messages: {
    accessForbidden: 'Accesul interzis',
    internalServerError: 'A apărut o eroare neașteptată a serverului. Vă rugăm să încercați din nou mai târziu.',
    validators: {
      required: 'Câmp obligatoriu',
      emailRequired: 'Te rugăm să introduci o adresă de e-mail validă',
      emailNotValid: 'Te rugăm să introduci o adresă de e-mail validă',
      emailNotRecognized: 'Podany e-mail nie jest zarejestrowany',
      emailIdentityLookup: 'Nie udało się zweryfikować adresu e-mail',
      emailLocked: 'Podany e-mail jest tymczasowo zablokowany w systemie Mastercard',
      paywayRequired: 'Pentru a plăti, selectați o bancă',
      creditCardRequired: 'Completați toate câmpurile',
      phoneRequired: 'Va rugam sa introduceti un numar de telefon valid',
      regulationRequired: 'Acceptați termenii și condițiile',
      requiredConsent: 'Aprobare necesară',
      codeNotValid: 'Podany kod nie jest prawidłowy',
      codeAccountLocked: 'Konto zostało tymczasowo zablokowane w systemie Mastercard'
    },
    validatorsAria: {
      required: 'Atenție. Câmpul formularului nu a fost completat. Câmp obligatoriu.',
      emailRequired: 'Atenție. Câmpul de e-mail nu a fost completat. Vă rugăm să introduceți o adresă de e-mail validă.',
      emailNotValid: 'Atenție. Adresa dvs. de e-mail conține o eroare. Vă rugăm să introduceți o adresă de e-mail validă.',
      emailNotRecognized: 'Uwaga. Podany e-mail nie jest zarejestrowany.',
      emailIdentityLookup: 'Uwaga. Nie udało się zweryfikować podanego adresu e-mail',
      emailLocked: 'Uwaga. Podany e-mail jest tymczasowo zablokowany w systemie Mastercard.',
      paywayRequired: 'Atenție. Plata nu a fost selectată. Selectați metoda de plată.',
      creditCardRequired: 'Atenție. Completați toate câmpurile cu datele cardului de credit.',
      phoneRequired: 'Atenție. Câmpul numărului de telefon conține o eroare. Vă rugăm să introduceți numărul de telefon corect.',
      codeNotValid: 'Uwaga. Podany kod nie jest prawidłowy.',
      codeAccountLocked: 'Uwaga. Konto zostało tymczasowo zablokowane w systemie Mastercard.'
    },
    success: {
      copy: 'Copiat!',
      codeSent: 'Kod został wysłany!'
    },
    errors: {
      getPdf: 'Descărcarea fișierului PDF a eșuat!',
      copy: 'Copierea a eșuat!',
      thankYouUpdate: 'Nu s-a putut salva adresa de e-mail!',
      groupInactive: 'În acest moment, este imposibil să efectuați un transfer folosind grupul {groupName}. Vă rugăm să selectați o altă metodă de plată.',
      paywayInactive: 'În acest moment nu este posibil să se facă un transfer de la banca selectată. Selectează o altă bancă sau metodă de plată.',
      googlePayInactive: 'Din păcate, Google Pay nu este disponibil pe dispozitivul dvs.',
      applePayInactive: 'Apple Pay este disponibil pentru utilizatorii de dispozitive Safari și iOS.',
      codeNotSent: 'Nie udało się wysłać ponownie kodu!',
      codeCounterExceeded: 'Przekroczono dozwoloną liczbę prób. Wprowadź dane karty ręcznie lub skorzystaj z innej metody płatności.'
    }
  },
  modal: {
    closeButton: 'Închide',
    confirmButton: 'Înteleg'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Plată recurentă',
        description: 'Introduceți detaliile cardului pe care îl veți utiliza pentru plată. Plățile automate vor fi debitate de pe card la o dată ulterioară.',
        labelInitWithRefund: 'Transfer de fonduri în scop de autentificare',
        labelInitWithRefundOrange: 'Adăugați un card de plată',
        labelInitWithPayment: 'Plata cu cardul',
        descriptionInitWithRefund: 'Introduceți detaliile cardului pe care doriți să îl verificați pentru plățile viitoare. În etapa următoare, vom lua un PLN de pe cardul pe care l-ați furnizat pentru a verifica dacă detaliile sunt corecte. După verificare, vom rambursa suma.',
        descriptionInitWithPayment: 'Introduceți detaliile cardului pe care îl veți utiliza pentru a efectua tranzacția și, în același timp, verificați-l pentru plățile viitoare.'
      },
      blik: {
        label: 'BLIK',
        description: 'Plătiți instantaneu prin introducerea codului BLIK, fără a vă conecta sau a vă înregistra'
      },
      card: {
        label: 'Plata cu cardul',
        description: 'Plătiți cu cardul'
      },
      pbl: {
        label: 'Transfer online',
        description: 'Selectați banca de la care doriți să efectuați o plată',
        descriptionVerify: 'Verificarea online a identității',
        descriptionServiceVerify: 'Selectați banca care vă va permite să verificați serviciul on-line'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Plata Visa Mobile'
      },
      wallet: {
        label: 'Portofel virtual',
        description: 'Plătiți fără să vă conectați la serviciile bancare online'
      },
      installments: {
        label: 'Împărțiți-l în tranșe',
        description: 'Plătiți pentru achizițiile dvs. folosind rate convenabile'
      },
      alior: {
        descriptionInfo1: 'Plătiți pentru achizițiile dvs. folosind rate convenabile',
        descriptionInfo2: '(verificați detaliile)',
        aliorModal: {
          text1: 'În funcție de setările site-ului de unde efectuați achiziția, puteți achiziționa produse în rate în următoarele variante:',
          text2: '<ul><li>10 rate 0%</li><li>20 rate 0%</li><li>de la 3 la 48 de rate cu un cost lunar de 1% (conform exemplului reprezentativ dat pentru o anumită tranzacție)</li></ul>'
        }
      },
      otp: {
        label: 'Plătește mai târziu',
        description: 'Cumpărați acum plătiți mai târziu',
        descriptionInfo1Mwf: 'Plătiți mai târziu factura - până la 45 de zile o dată sau în mai multe rate egale',
        descriptionInfo1Ecommerce: 'Plătiți mai târziu - până la 45 de zile o dată sau în mai multe rate egale',
        descriptionInfo2Mwf: 'Informații despre intermediarul de credit și cost',
        descriptionInfo2Ecommerce: 'Informații despre costuri',
        labelPayka: 'Payka',
        descriptionPayka: 'Plătiți mai târziu factura - într-o singură tranșă de până la 30 de zile sau în 4 rate egale <a href="" id="paykaShowInModal">Informații despre intermediarul de credit și cost</a>',
        descriptionBlikPayLater: 'Serviciul este disponibil la Millenium Bank și VeloBank',
        paykaModalMwf: {
          header1: 'Informații privind intermediarul de credit',
          text1: 'Autopay S.A., cu sediul în Sopot, acționează în calitate de intermediar de credit al Paytree S.A., cu sediul în Gdańsk (Creditorul), autorizat să prezinte clienților oferta de credit și să redirecționeze către site-ul web al Creditorului, inclusiv cererea de credit. Detalii despre ofertă la: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Detalii privind costurile',
          text2: 'Plătiți mai târziu - până la 45 de zile o dată sau în mai multe rate egale. Date de cost pentru un exemplu reprezentativ: rambursare în 45 de zile DAE 0%; RAmbursarea ratei DAE 86,83%. Detalii despre oferta la: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Detalii privind costurile',
          text2: 'Plătiți mai târziu - până la 45 de zile o dată sau în mai multe rate egale. Date de cost pentru un exemplu reprezentativ: rambursare în 45 de zile DAE 0%; RAmbursarea ratei DAE 86,83%. Detalii despre oferta la: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Cumără acum, plătește  în decurs de 30 de zile',
          header2: 'Cod BLIK',
          text2: 'Introdu codul BLIK și îți oferim o limită de cumpărături de până la 4.000 PLN',
          header3: 'Cumperi produse și plătești pentru ele mai târziu',
          text3: 'Ai timp să verifici dacă totul ți se potrivește',
          header4: 'Plătiți gratuit în 30 de zile',
          text4: 'De asemenea, puteți returna achizițiile în termenul specificat de magazin'
        }
      },
      companyPayments: {
        label: 'Plăți corporative',
        description: 'Termenele de plată în avans pentru achizițiile corporative cu 30 de zile'
      },
      transfer: {
        label: 'Detalii transfer',
        description: 'Solicitați un transfer bancar utilizând detaliile furnizate'
      },
      other: {
        label: 'Altele',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Îmi pare rău. În prezent nu putem procesa transferul dumneavoastră. Banca selectată nu este disponibilă în prezent',
        blockMultipleTransactions: 'Îmi pare rău. Tranzacția există deja și așteaptă să fie depusă.',
        blockPaidTransactions: 'Îmi pare rău. Tranzacția a fost deja plătită. Nu puteți efectua din nou o plată.',
        canceledTransactions: 'Tranzacția pentru care încercați să plătiți a fost anulată și nu este disponibilă. Vă rugăm să începeți o nouă tranzacție.',
        declined: 'Autorizație refuzată.',
        generalError: 'Wystąpił chwilowy problem z połączeniem. W tej chwili nie możemy zrealizować Twojego przelewu. Zapraszamy później.',
        insufficientStartAmount: 'Îmi pare rău. Limita sumei de transfer a fost depășită.',
        internalServerError: 'Upsss... a eșuat :(. Lucrăm la o soluție la această problemă. Vă rugăm să încercați din nou mai târziu.',
        nonAccountedLimitExceeded: 'Plata nu poate fi procesată deoarece vânzătorul a atins limita lunară de vânzări.',
        outdatedError: 'Timpul pentru finalizarea plății a trecut.',
        paid: 'Tranzacție finalizată cu succes',
        paywayLoaded: 'Pagina de transfer rapid a fost încărcată',
        paywaylistLoaded: 'Pagina de selectare a metodei de plată a fost încărcată'
      }
    }
  },
  maintenance: {
    text1: 'În acest moment se desfășoară întreținerea și curățarea serverelor portalului de plăți Autopay cu ocazia Anului Nou și, prin urmare,nu este posibilă efectuarea de plăți în acest moment. Ne cerem scuze pentru neplăcerile create',
    text2: 'Gateway se va deschide din nou pe 16 ianuarie la ora 10:00.',
    text3: 'Vă mulțumesc pentru înțelegere.'
  }
}
